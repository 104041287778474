<template>
  <div class="recommend-block__item">
    <p>Благодарим Вас за интерес к тренерской и наставнической деятельности совместно со Школой экспорта РЭЦ!</p>
    <p>Мы получили сведения заполненной Вами анкеты. Наши специалисты рассмотрят направленную информацию и свяжутся с Вами для решения дальнейших вопросов по обучению на тренера или наставника Школы экспорта РЭЦ. </p>
    <router-link to="/lk" class="link-target">
      <span>Вернуться в Мой кабинет</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "RecommendThird"
}
</script>

<style scoped>

</style>