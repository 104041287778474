<template>
  <div class="section__lk-content container-shadow fill-white recommend-block" v-if="loaded && recommend.state">
    <div class="section__lk-title">
      <img class="pr-2" :src=" '/pic/recommend/icon_state' + recommend.state + '.svg'" />{{ pageTitle }}
    </div>
    <div>
      <img :src=" '/pic/recommend/state' + recommend.state + '.svg'" />
      <h2 style="margin: 32px 0 0; font-weight: 700;font-size: 32px;line-height: 36px;">Уважаемый(-ая) {{ user.shortname }}!</h2>
      <recommend-first v-if="recommend.state === 1" :data="recommend"/>
      <recommend-second v-else-if="recommend.state === 2 || recommend.state === 3" :data="recommend" />
      <recommend-third  v-else-if="recommend.state === 4" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {TokenService} from "../../../services/token.service";
import RecommendFirst from "@/components/profile/recommend/RecommendFirst.vue";
import RecommendSecond from "@/components/profile/recommend/RecommendSecond.vue";
import RecommendThird from "@/components/profile/recommend/RecommendThird.vue";

export default {
  name: "Recommend",
  components: {RecommendThird, RecommendSecond, RecommendFirst},
  data() {
    return {
      loaded: false,
      user: TokenService.getUserObj(),
      pageTitle: 'Мы бесплатно подберем для Вас образовательную программу!'
    }
  },
  mounted() {
    this.getRecommend({})
    this.loaded = true
    switch (this.recommend.state) {
      case 2:
      case 3:
        this.pageTitle = 'Рекомендации по образовательным программам готовы!';
        break;
      case 4:
        this.pageTitle = 'Спасибо, мы получили сведения заполненной Вами анкеты!';
        break;
      default:
        break;
    }
  },
  methods: {
    ...mapActions(['getRecommend'])
  },
  computed: {
    ...mapGetters(['recommend'])
  }
}
</script>

<style lang="scss">
</style>