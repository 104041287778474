<template>
  <div class="recommend-block__item" v-if="data.test">
    <p>Мы готовы подобрать для Вас образовательные программы и продукты, которые наиболее подойдут Вам с учетом уровня Ваших экспортных компетенций.</p>
    <p>Предлагаем пройти тест, который состоит из {{data.test.questions_count}} вопросов. Тест включает в себя блоки вопросов по разным темам экспортной деятельности и позволит комплексно оценить Ваши знания, а также подготовить для Вас рекомендации по дальнейшему обучению.</p>
    <router-link v-if="data.test" :to="'/lk/test/' + data.test.id + '?start=1'" class="link-target">
      <span>Пройти тест</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "RecommendFirst",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
</style>