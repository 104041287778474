<template>
  <div class="recommend-block__item">
    <h2 class="mt-2">
      Общие рекомендации
    </h2>
    <p v-html="data.recommend.common.text"></p>
    <p>
      <a :href="data.recommend.common.button.link+'#top'" class="link-target">
        <span>{{ data.recommend.common.button.text }}</span>
      </a>
    </p>
    <h2 v-if="data.recommend.detail" class="mb-2 mt-5">
      Детализированные рекомендации (помогут точечно повысить знания):
    </h2>
    <div v-if="data.recommend.detail" class="wrapper-course-components">
      <div v-for="(detail, index) in data.recommend.detail" :key="index" class="block-course-component__wrap" style="border-bottom: 1px dashed #BDBDBD;">
        <div class="block-course-component success test" style="padding: 25px 0 25px 40px;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 5; line-clamp: 2; min-height: 38px;">
          <div class="block-course-component__inner" >
            <div class="block-course-component__left">
              <img src="/pic/recommend/book.svg" />
            </div>
            <div class="block-course-component__middle" style="margin-right: 136px;">
              <div class="block-course-component__type" style="font-weight: 400;font-size: 16px;line-height: 19px;color: #333333;">
                {{ detail.name }}
              </div>
              <div class="block-course-component__title"></div>
            </div>
            <div class="block-course-component__right" style="width: 136px;">
              <div class="block-course-component__right__inner" style="padding-right: 0;">
                <div class="block-course-component__stat" style="justify-content: start; display: block;">
                  <div class="block-course-component__stat__text">
                    <div class="text">
                      <a :href="detail.link + '#top'" style="text-decoration: none; border: none; font-weight: 700;font-size: 16px;line-height: 19px;color: #1C75BC; margin-right: 18px;">Перейти</a>
                      <img v-if="detail.isSolved" src="/pic/recommend/check.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecommendSecond",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>